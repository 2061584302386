import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  FormGroup,
  Switch,
  Modal,
  Card,
} from "@mui/material";
import CustomTable from "../../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import SearchButton from "../../components/search/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getregion } from "../../slices/setting";
import { createcustomer, createinvoice, getpaymentbycus, updateinvoicebyid, updatepaymentbyid } from "../../slices/customer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getpurchase } from "../../slices/purchase";
import BasicTabs from "../../components/tabs/Invitab";
import { deleteaccessorystock, deleteproductstock, deletesparestock, getaccessorystock, getproductstock, getsparestock } from "../../slices/inventory";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: '100vh',
  overflow:'scroll',
  p: 2,
};

const Editinvoice = ({ setLoader, show }) => {

  const {state} = useLocation();

  console.log(state)

  const [open, setOpen] = React.useState(false);

  const [file, setFile] = useState(null);

  const [assignedproduct, setAssignedproduct] = useState();

  const[gst, setGst] = useState(true);

  const [discount, setDiscount] = useState({
    val: '0',
    unit:'₹'
  })

  const [selectedItem, setSelectedItem] = useState([]);

  const {vehicles: vehicleStockData} = useSelector((state) => state.inventory) || [];

  const {accessories: accessoriesStockData} = useSelector((state) => state.inventory) || [];

  const {spares: spareStockData} = useSelector((state) => state.inventory) || [];


  const {payments: paymentData} = useSelector((state) => state.customer) || [];

  const user = JSON.parse(localStorage.getItem('user'));

  const dealerState =  JSON.parse(user.billing_address).state; 

  const handleOpen = () =>{
    setOpen(true);
    setLoader(true);
    dispatch(getproductstock())
    dispatch(getsparestock())
    dispatch(getaccessorystock())
    .then(() => {
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  }
  const handleClose = () =>{
    setOpen(false);
    setAssignedproduct(null);
  }

  const [customerdata, setCustomerdata] = useState({
      "dealer_id": user?.dealer_id,
      "oem_id": user?.oem_id,
      "customer_id": state?.cusData?.id,
      "customer_name": state?.cusData?.first_name,
      "invoice_date":"",
      "invoice_type":"Product",
      "due_date":"",
      "item_list":["listdata","listdata","listdata"],
      "terms_conditions":"",
      "file_url":file,
      "sub_total": "",
      "sgst":"",
      "cgst":"",
      "igst":"",
      "discount":"",
      "status":"",
      "total":"",
      "pending_payments":""
  })

  useEffect(()=>{
    if(state?.editData){
      setCustomerdata({...state.editData, item_list: JSON.parse(state.editData.item_list)})
      setSelectedItem(JSON.parse(state.editData.item_list))
    }
  }, [state])

  const priceGroups = {
    state:"",
    ex_showroom:"",
    dealer:""
  }
  
  const colorGroups = {
    color_id: 1,
    name:"Black",
    code:"#000"
  }
  
  const moreData = {
    field_id: 1,
    field:"",
    value:""
  }

  
  const variantIntiData = {
    vehicle_id: assignedproduct?.vehicle?.id,
    variant_name: "",
    released_date: "",
    subsidy: "",
    motor_name: "",
    motor_type: "",
    motor_voltage: "",
    motor_capacity: "",
    motor_warranty_km: "",
    motor_warranty_months: "",
    motor_warranty_type: "",
    battery_name: "",
    battery_type: "",
    battery_lifecycle: "",
    battery_capacity: "",
    battery_warranty_km: "",
    battery_warranty_months: "",
    battery_warranty_type: "",
    charger_name: "",
    charger_capacity: "",
    charger_type: "",
    charger_warranty_km: "",
    charger_warranty_months: "",
    charger_warranty_type: "",
    converter_name: "",
    converter_capacity: "",
    converter_warranty_km: "",
    converter_warranty_months: "",
    converter_warranty_type: "",
    controller_name: "",
    controller_capacity: "",
    controller_warranty_km: "",
    controller_warranty_months: "",
    controller_warranty_type: "",
    display_name: "",
    display_type: "",
    display_warranty_km: "",
    display_warranty_months: "",
    display_warranty_type: "",
    color_details: [colorGroups],
    other_details: [],
    price_groups: []
   }


   const [variantData, setVariantData] = React.useState([variantIntiData]);

  useEffect(()=>{
    if(file){
      setCustomerdata({...customerdata, file_url: file})
    }
  },[file])

  const payId = paymentData.filter(va => va.invoice_id === null).map(obj =>{return obj}).flat();

  console.log(payId);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const arrowBack = () => {
    navigate(-1);
  };

  const handleItemSubmit = () => {
    if(assignedproduct?.manual){
    selectedItem.push({...assignedproduct, variant: {...variantData[0], price_groups: JSON.stringify(variantData[0]?.price_groups), color_details: JSON.stringify(variantData[0]?.color_details), other_details: JSON.stringify(variantData[0]?.other_details) }})
    }
    else{
      selectedItem.push(assignedproduct)
    }
    handleClose();
  }

  console.log(assignedproduct);

  console.log(selectedItem);

  const handledeleteItem = (id) =>{
    const remainData =selectedItem.filter((v)=> {return(v.id !== id)});
    setSelectedItem(remainData);
  }

  const handleGST = (e) => {
    setGst(e.target.checked);
  }

  const subTotal1 = selectedItem?.filter(va => va?.product_type === "Vehicle").map((obj) =>Number(JSON.parse(obj?.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );

    const subTotal2 = selectedItem?.filter(va => va?.product_type === "Spare").map((obj) =>Number(obj?.spare?.price))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );

    const subTotal3 = selectedItem?.filter(va => va?.product_type === "Accessory").map((obj) =>Number(obj?.accessory?.price))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );

    const subTotal4 = selectedItem?.filter(va => va?.product_type === "Others").map((obj) =>Number(obj?.price))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );


    const subTotal = Number(subTotal1) + Number(subTotal2) + Number(subTotal3) + Number(subTotal4)

    const sgst1 = selectedItem?.filter(va => va?.product_type === "Vehicle").map((obj) =>Number(JSON.parse(obj?.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom)* (obj?.vehicle?.sgst/100))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );

    const sgst2 = selectedItem?.filter(va => va?.product_type === "Spare").map((obj) =>Number(obj?.spare?.price)* (obj?.spare?.sgst/100))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );

    const sgst3 = selectedItem?.filter(va => va?.product_type === "Accessory").map((obj) =>Number(obj?.accessory?.price)* (obj?.accessory?.sgst/100))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );

    const sgst4 = selectedItem?.filter(va => va?.product_type === "Others").map((obj) =>Number(obj?.price)* (obj?.sgst/100))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );

    const sgst = Number(sgst1) + Number(sgst2) + Number(sgst3) + Number(sgst4)

    const cgst1 = selectedItem?.filter(va => va?.product_type === "Vehicle")?.map((obj) =>Number(JSON.parse(obj?.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom)* (obj?.vehicle?.cgst/100))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );

    const cgst2 = selectedItem?.filter(va => va?.product_type === "Spare")?.map((obj) =>Number(obj?.spare.price)* (obj?.spare?.cgst/100))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );

    const cgst3 = selectedItem?.filter(va => va?.product_type === "Accessory")?.map((obj) =>Number(obj?.accessory.price)* (obj?.accessory?.cgst/100))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );

    const cgst4 = selectedItem?.filter(va => va?.product_type === "Others")?.map((obj) =>Number(obj?.price)* (obj?.cgst/100))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );

    const cgst = Number(cgst1) + Number(cgst2) + Number(cgst3) + Number(cgst4);

    const igst1 = selectedItem?.filter(va => va?.product_type === "Vehicle")?.map((obj) =>Number(JSON.parse(obj?.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom)* (obj?.vehicle?.igst/100))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );

    const igst2 = selectedItem?.filter(va => va?.product_type === "Spare")?.map((obj) =>Number(obj?.spare?.price)* (obj?.spare?.igst/100))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );

    const igst3 = selectedItem?.filter(va => va?.product_type === "Accessory")?.map((obj) =>Number(obj?.accessory?.price)* (obj?.accessory?.igst/100))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );

    const igst4 = selectedItem?.filter(va => va?.product_type === "Others")?.map((obj) =>Number(obj?.price)* (obj?.igst/100))
    ?.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue,
      0
    );

    const igst = Number(igst1) + Number(igst2) + Number(igst3) + Number(igst4);

    const discVal = discount.unit === '₹' ? discount.val : subTotal*discount.val/100;

    const total = Number(subTotal)+ (gst ? (Number(cgst)+Number(sgst)+Number(igst)) : Number(0) ) - discVal

    console.log(selectedItem);


  const handleSubmit = (e) => {
    e.preventDefault();

    setLoader(true)
    if(payId.length>0){
      const inv_id = state?.editData?.invoice_id
      dispatch(updateinvoicebyid({inv_id, formData: {...customerdata, item_list: JSON.stringify(selectedItem), sub_total:subTotal, sgst: (gst ? sgst : '0'),
      cgst: (gst ? cgst : '0'),
      igst: (gst ? igst : '0'),
      discount: discVal ,
      status: Number(payId[0].total) === Number(total) ? 'Paid':'Pending',
      invoice_type:"Product",
      total: total, pending_payments: (total-payId[0].total).toString() }}))
      .then((data) => {
        for(let i= 0; i < selectedItem.length ; i++){
          const inv_id = selectedItem[i].id;
          if(selectedItem[i].product_type === "Vehicle"){
            dispatch(deleteproductstock({inv_id}))
          }
          if(selectedItem[i].product_type === "Spare"){
            dispatch(deletesparestock({inv_id}))
          }
          if(selectedItem[i].product_type === "Accessory"){
            dispatch(deleteaccessorystock({inv_id}))
          }
        }
        const formData = {invoice_id: data.payload.customerdata.data.invoice_id};
            const pay_id = payId[0].payment_id
            dispatch(updatepaymentbyid({pay_id, formData}))
        navigate(-1);
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
    }
    else{
      const inv_id = state?.editData?.invoice_id
      dispatch(updateinvoicebyid({inv_id, formData: {...customerdata, item_list: JSON.stringify(selectedItem), sub_total:subTotal, sgst: (gst ? sgst : '0'),
      cgst: (gst ? cgst : '0'),
      igst: (gst ? igst : '0'),
      discount: discVal ,
      status:"Pending",
      invoice_type:"Product",
      total: total, pending_payments: total.toString() }}))
      .then(() => {
        for(let i= 0; i < selectedItem.length ; i++){
          const inv_id = selectedItem[i].id;
          if(selectedItem[i].product_type === "Vehicle"){
            dispatch(deleteproductstock({inv_id}))
          }
          if(selectedItem[i].product_type === "Spare"){
            dispatch(deletesparestock({inv_id}))
          }
          if(selectedItem[i].product_type === "Accessory"){
            dispatch(deleteaccessorystock({inv_id}))
          }
        }
        navigate(-1);
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
    }
  };

  React.useEffect(()=>{
    setLoader(true)
      dispatch(getpaymentbycus(state?.cusData?.id))
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
      <Box  component="form"
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}
    >
        <Grid container spacing={3} sx={{ 
          paddingBottom: 2, 
          alignItems: "center" 
          }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{fontWeight:'bold'}}>Update Invoice</Typography>
          </Grid>
                <Grid item xs={2}>
                <Typography >Customer Name *</Typography>
                </Grid>
                <Grid item xs={4}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                readOnly
                value={customerdata.customer_name}
                InputProps={{
                readOnly: true,
              }}
              />
                </Grid>
                <Grid item xs={1}>
                <Typography >Add GST *</Typography>
                </Grid>
                <Grid item xs={5}>
                <FormGroup>
      <FormControlLabel control={<Switch
      checked={gst}
      onChange={(e) => handleGST(e)} 
       defaultChecked />} />
      </FormGroup>
                </Grid>
                <Grid item xs={2}>
                <Typography >Invoice Date *</Typography>
                </Grid>
                <Grid item xs={4}>
                <TextField
                type="date"
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={customerdata.invoice_date}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        invoice_date: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={2}>
                <Typography >Due Date *</Typography>
                </Grid>
                <Grid item xs={4}>
                <TextField
                size="small"
                type="date"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={customerdata.due_date}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        due_date: e.target.value,
                      })
                    }
              />
                </Grid>
        </Grid>
        <Divider sx={{mb: 2}}/>
        <TableContainer component={Paper} sx={{boxShadow:'none'}}>
      <Table sx={{ minWidth: 650, "& .MuiTableCell-root": {
      border: "1px solid rgba(224, 224, 224, 1)"
    } }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2}>Item Details</TableCell>
            <TableCell rowSpan={2} align="left">HSN</TableCell>
            <TableCell rowSpan={2} align="left">Rate</TableCell>
            <TableCell colSpan={2} align="center">SGST</TableCell>
            <TableCell colSpan={2} align="center">CGST</TableCell>
            <TableCell colSpan={2} align="center">IGST</TableCell>
            <TableCell  rowSpan={2} align="left">Amount</TableCell>
            <TableCell  rowSpan={2} align="left">Action</TableCell>
          </TableRow>
          <TableRow>
            <TableCell  align="left">%</TableCell>
            <TableCell  align="left">Amount</TableCell>
            <TableCell  align="left">%</TableCell>
            <TableCell align="left">Amount</TableCell>
            <TableCell  align="left">%</TableCell>
            <TableCell align="left">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedItem.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: "1px solid rgba(224, 224, 224, 1)" } }}
            >
            {row?.product_type === "Vehicle" ?
              <TableCell component="th" scope="row">
            {row?.vehicle?.product_name} <br></br>
Vehicle Model: {row?.variant?.variant_name} <br/>

Vehicle Color: {JSON.parse(row?.variant?.color_details).filter(obj => obj.code === row?.stock?.color)[0]?.name} <br/>

Chassis No: {row?.stock?.chasis_no} <br/>

Motor name: {row?.variant?.motor_name} <br/>

Motor No: {row?.stock?.motor_no} <br/>

Motor type: {row?.variant?.motor_type} <br/>

Motor warranty: {row?.variant?.motor_warranty_months}  <br/>

Battery name: {row?.variant?.battery_name} <br/>

Battery number: {row?.stock?.battery_no} <br/>

Battery type: {row?.variant?.battery_type} <br/>

Battery warranty: {row?.variant?.battery_warranty_months}  <br/>

Charger name: {row?.variant?.charger_name} <br/>

Charger type: {row?.variant?.charger_type} <br/>

Charger capacity: {row?.variant?.charger_capacity} <br/>

Charger warranty: {row?.variant?.charger_warranty_months}  <br/>

Controller No: {row?.stock?.controller_no} <br/>

Converter No: {row?.stock?.convertor_no} <br/>
              </TableCell>
              :
              row?.product_type === "Others" ?
              <TableCell component="th" scope="row">
              {row?.itemDetails}
              </TableCell>
              :
              <TableCell component="th" scope="row">
            {row?.vehicle?.product_name} <br></br>
Vehicle Model: {row?.variant?.variant_name} <br/>

Product Name: {row?.stock?.product} <br/>

Company: {row?.stock?.company} <br/>

Part Code: {row?.stock?.part_code} <br/>
              </TableCell>
            }
            { row?.product_type === "Vehicle" ? 
              <TableCell >{row?.vehicle?.hsn_no}</TableCell>
              :
              row?.product_type === "Spare" ?
              <TableCell >{row?.spare?.hsn_no}</TableCell>
              :
              row?.product_type === "Accessory" ?
              <TableCell >{row?.spare?.hsn_no}</TableCell>
              :
              <TableCell >{row?.hsn}</TableCell>
            }
            { row?.product_type === "Vehicle" ? 
            <TableCell >{JSON.parse(row?.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom}</TableCell>
              :
              row?.product_type === "Spare" ?
              <TableCell >{row?.spare?.price}</TableCell>
              :
              row?.product_type === "Accessory" ?
              <TableCell >{row?.accessory?.price}</TableCell>
              :
              <TableCell >{row?.price}</TableCell>
            }
            { row?.product_type === "Vehicle" ? 
            <TableCell >{gst ? (row?.vehicle?.sgst) : '-'}</TableCell>
              :
              row?.product_type === "Spare" ?
              <TableCell >{gst ? (row?.spare?.sgst) : '-'}</TableCell>
              :
              row?.product_type === "Accessory" ?
              <TableCell >{gst ? (row?.accessory?.sgst) : '-'}</TableCell>
              :
              <TableCell >{row?.sgst}</TableCell>
            }
            { row?.product_type === "Vehicle" ? 
            <TableCell >{gst ? (JSON.parse(row?.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom * (row?.vehicle?.sgst/100)) : '-'}</TableCell>              :
              row?.product_type === "Spare" ?
              <TableCell >{gst ? ( row?.spare?.price * (row?.spare?.sgst/100)) : '-'}</TableCell>              :
              row?.product_type === "Accessory" ?
              <TableCell >{gst ? ( row?.accessory?.price * (row?.accessory?.sgst/100)) : '-'}</TableCell>   
              :
              <TableCell >{gst ? ( row?.price * (row?.sgst/100)) : '-'}</TableCell> 
                }
              { row?.product_type === "Vehicle" ? 
            <TableCell >{gst ? (row?.vehicle?.cgst) : '-'}</TableCell>
              :
              row?.product_type === "Spare" ?
              <TableCell >{gst ? (row?.spare?.cgst) : '-'}</TableCell>
              :
              row?.product_type === "Accessory" ?
              <TableCell >{gst ? (row?.accessory?.cgst) : '-'}</TableCell>
              :
              <TableCell >{gst ? (row?.cgst) : '-'}</TableCell>
            }
            { row?.product_type === "Vehicle" ? 
            <TableCell >{gst ? (JSON.parse(row?.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom * (row?.vehicle?.cgst/100)) : '-'}</TableCell>              :
              row?.product_type === "Spare" ?
              <TableCell >{gst ? ( row?.spare?.price * (row?.spare?.cgst/100)) : '-'}</TableCell>              :
              row?.product_type === "Accessory" ?
              <TableCell >{gst ? ( row?.accessory?.price * (row?.accessory?.cgst/100)) : '-'}</TableCell>            
              :
              <TableCell >{gst ? ( row?.price * (row?.cgst/100)) : '-'}</TableCell>
               }
              { row?.product_type === "Vehicle" ? 
            <TableCell >{gst ? (row?.vehicle?.igst) : '-'}</TableCell>
              :
              row?.product_type === "Spare" ?
              <TableCell >{gst ? (row?.spare?.igst) : '-'}</TableCell>
              :
              row?.product_type === "Accessory" ?
              <TableCell >{gst ? (row?.accessory?.igst) : '-'}</TableCell>
              :
              <TableCell >{gst ? (row?.igst) : '-'}</TableCell>
            }
            { row?.product_type === "Vehicle" ? 
            <TableCell >{gst ? (JSON.parse(row?.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom * (row?.vehicle?.igst/100)) : '-'}</TableCell>              :
              row?.product_type === "Spare" ?
              <TableCell >{gst ? ( row?.spare?.price * (row?.spare?.igst/100)) : '-'}</TableCell>              :
              row?.product_type === "Accessory" ?
              <TableCell >{gst ? ( row?.accessory?.price * (row?.accessory?.igst/100)) : '-'}</TableCell>             
              :
              <TableCell >{gst ? ( row?.price * (row?.igst/100)) : '-'}</TableCell> 
              }

              { row?.product_type === "Vehicle" ? 
              <TableCell >{gst ? (Number(JSON.parse(row?.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom)+Number(JSON.parse(row?.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom * (row?.vehicle?.sgst/100))+Number(JSON.parse(row?.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom * (row?.vehicle?.cgst/100))+Number(JSON.parse(row?.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom * (row?.vehicle?.igst/100))) : Number(JSON.parse(row?.variant?.price_groups).filter(obj => obj.state === dealerState)[0]?.ex_showroom)}</TableCell>             :
              row?.product_type === "Spare" ?
              <TableCell >{gst ? (Number(row?.spare?.price)+Number((row?.spare?.price) * (row?.spare?.sgst/100))+Number((row?.spare?.price) * (row?.spare?.cgst/100))+Number((row?.spare?.price) * (row?.spare?.igst/100))) : Number(row?.spare?.price)}</TableCell>
                :
                row?.product_type === "Accessory" ?
                <TableCell >{gst ? (Number(row?.accessory?.price)+Number((row?.accessory?.price) * (row?.accessory?.sgst/100))+Number((row?.accessory?.price) * (row?.accessory?.cgst/100))+Number((row?.accessory?.price) * (row?.accessory?.igst/100))) : Number(row?.accessory?.price)}</TableCell>  
                :
                <TableCell >{gst ? (Number(row?.price)+Number((row?.price) * (row?.sgst/100))+Number((row.price) * (row?.cgst/100))+Number((row?.price) * (row?.igst/100))) : Number(row?.price)}</TableCell>            
                }
              <TableCell>
              {/* {row?.manual && 
              <Button
                                          sx={{
                                            background: "#3d07dc",
                                            marginRight: 2,
                                            height: '40px',
                                            width:'40px',
                                            minWidth: 0
                                          }}
                                          onClick={() =>{
                                            handleOpen()
                                            setAssignedproduct(row);
                                          }}
                                        >
                                          <EditIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></EditIcon>
                                        </Button>
              } */}
              <Button
                                          sx={{
                                            background: "#dc0707",
                                            marginRight: 2,
                                            height: '40px',
                                            width:'40px',
                                            minWidth: 0
                                          }}
                                          onClick={() => handledeleteItem(row.id)}
                                        >
                                          <DeleteIcon
                                            sx={{ color: "#fff", fontSize: 17 }}
                                          ></DeleteIcon>
                                        </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
                  <Button onClick={() => handleOpen()} variant="text" size="small" sx={{my: 3}}>+ Add Another Item</Button>
                  <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <BasicTabs priceGroups={priceGroups} moreData={moreData} colorGroups={colorGroups} variantData={variantData} setVariantData={setVariantData} setLoader={setLoader} vehicleData={vehicleStockData.filter(obj => !(selectedItem.map(va => va.id).flat()).includes(obj.id))} spareData={spareStockData.filter(obj => !(selectedItem.filter(ab => ab.product_type === "Spare").map(va => va.id).flat()).includes(obj.id))} accessoriesData={accessoriesStockData.filter(obj => !(selectedItem.filter(ab => ab.product_type === "Accessory").map(va => va.id).flat()).includes(obj.id))}  setAssignedproduct={setAssignedproduct} assignedproduct={assignedproduct}/>
                          <Divider orientation="horizontal" sx={{ my: 2 }} />
                            <Box sx={{ textAlign: "end" }}>
                            <Button variant="outlined"
                            onClick={handleClose}
                            sx={{mr: 1}}
                            >
                                Cancel
                              </Button>
                              <Button onClick={handleItemSubmit} variant="contained">
                                Confirm
                              </Button>
                            </Box>
                        </Box>
                      </Modal>            
    <Grid container spacing={2}>
          <Grid item xs={9} sx={{textAlign:'end'}}>
              <Typography variant="body1">Sub Total</Typography>
              <Typography  variant="body1">SGST</Typography>
              <Typography  variant="body1">CGST</Typography>
              <Typography  variant="body1">IGST</Typography>
              <Typography  variant="body1" sx={{mt: 1}}>Discount</Typography>
              <Typography  variant="h6" sx={{mt: 2}}>Total (₹)</Typography>
          </Grid>
          <Grid item xs={3} sx={{textAlign:'end'}}>
              <Typography variant="body1">₹ {subTotal ? subTotal : '0.00'}</Typography>
              <Typography variant="body1">₹ {(sgst && gst) ? sgst :'0.00'}</Typography>
              <Typography variant="body1">₹ {(cgst && gst) ? cgst :'0.00'}</Typography>
              <Typography variant="body1">₹ {(igst && gst) ? igst :'0.00'}</Typography>
              <Box sx={{display:'flex', alignItems:'center', justifyContent:'flex-end', gap: 1}}>
              <TextField sx={{width:'80px'}} size="small" 
              value={discount?.val}
              onChange={(e)=>{
                setDiscount({...discount, val: e.target.value})
              }}
              id="outlined-basic" variant="outlined" />
              <FormControl size="small">
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={discount.unit}
    onChange={(e)=>{
                setDiscount({...discount, unit: e.target.value})
              }}
  >
    <MenuItem value={'₹'}>₹</MenuItem>
    <MenuItem value={'%'}>%</MenuItem>
  </Select>
</FormControl>
              <Typography variant="body1">₹ {discVal ? discVal :'0.00'}</Typography>

              </Box>
              <Typography variant="h6" sx={{mt: 2}}>₹ {total ? total :'0.00'}</Typography>
          </Grid>
    </Grid>

            <Grid container columnSpacing={5} sx={{mt: 2}}>
                  <Grid item xs={6}>
                <Typography variant="h6">Terms & Conditions *</Typography>
                <TextField
                sx={{mt: 2}}
                rows={4}
                multiline
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={customerdata.terms_conditions}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        terms_conditions: e.target.value,
                      })
                    }
              />
            </Grid>
            <Grid item xs={6}>
            <Typography variant="h6">Attach Image</Typography>
                <CloudUpload setLoader={setLoader} file={customerdata.file_url} setFile={setFile}/>
                </Grid>
            </Grid>
            <Divider sx={{my: 3}}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{textAlign:'end'}}>
                  <Button sx={{mr: 2}} onClick={arrowBack} variant="outlined">Cancel</Button>
                  <Button type="submit" variant="contained">Update Invoice</Button>
                </Grid>
            </Grid>
      </Box>
    </div>
  );
};

export default Editinvoice;