import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  FormGroup,
  Switch,
  Modal,
  Card,
} from "@mui/material";
import CustomTable from "../../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import SearchButton from "../../components/search/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getregion } from "../../slices/setting";
import { createcustomer, createinvoice, createpayment, createservice, getinvoicebycus, updateinvoicebyid } from "../../slices/customer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getpurchase } from "../../slices/purchase";
import BasicTabs from "../../components/tabs/Invitab";
import { deleteproductstock, getproductstock } from "../../slices/inventory";
import DeleteIcon from "@mui/icons-material/Delete";


const Addservice = ({ setLoader, show }) => {

  const {state} = useLocation();

  const [moreno, setMoreno] = React.useState(1);

  const moreData = {
    field_id: 0,
    value:""
  }

  const {invoices: invoiceData} = useSelector((state) => state.customer) || [];

  const user = JSON.parse(localStorage.getItem('user'));

  const [file, setFile] = useState(null);

  const vehicledetailsData = (invoiceData.filter(va => va.invoice_type !== "Service" && va.status === "Paid")).map(obj => JSON.parse(obj.item_list)).flat();

  const vehicleSelectOption = vehicledetailsData.filter(ab => ab.product_type === "Vehicle" || !ab.hasOwnProperty("product_type")).map(obj => {return {
    id: obj.id,
    vehicle_name: obj.vehicle.product_name,
    variant_name: obj.variant.variant_name
  }})

  console.log(vehicleSelectOption);

  const [customerdata, setCustomerdata] = useState({
      "dealer_id": user?.dealer_id,
      "customer_id": state?.cusData?.id,
      "select_vehicle":"",
      "chasis_number":"",
      "number_service":"",
      "meter_reading":"",
      "repeat_job":"",
      "rsa_on_site":"",
      "remarks":[moreData ],
      "file_url":file,
      "notes":"",
      "status":""
  })

  useEffect(()=>{
    if(file){
      setCustomerdata({...customerdata, file_url: file})
    }
  },[file])



  const dispatch = useDispatch();

  const navigate = useNavigate();

  const arrowBack = () => {
    navigate(-1);
  };

  const handleFieldChange = (field_id, event) => {
    const newValue = event.target.value;
    console.log(field_id)
    setCustomerdata(prevItems => {
          return { ...prevItems, remarks: customerdata.remarks.map(item1=>{
            if (item1.field_id === field_id) {
              return { ...item1, value: newValue };
            }
            return item1;
          }) };
        }
      );
  };


    const addMoreDetails = () => {
      customerdata.remarks.push({...moreData, field_id:moreno});
      setMoreno(moreno+1);
    }

    const removeField = (field_id) =>{
      setMoreno(moreno-1);
      setCustomerdata(prevItems => {
            return { ...prevItems, remarks: customerdata.remarks.filter(item1=>{
                return (item1.field_id !== field_id) ;
            }) };
        });
    }

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoader(true)

    dispatch(createservice({...customerdata, select_vehicle: `${vehicleSelectOption.filter(obj => obj.id === customerdata.select_vehicle)[0].vehicle_name}: ${vehicleSelectOption.filter(obj => obj.id === customerdata.select_vehicle)[0].variant_name}`,
    status:"In Progress" }))
    .then(() => {
      navigate(-1);
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  };

  
  React.useEffect(()=>{
    setLoader(true)
      dispatch(getinvoicebycus(state?.cusData?.id))
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
      <Box  component="form"
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems: "center" }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{fontWeight:'bold'}}>New Service</Typography>
          </Grid>
          <Grid item xs={2}>
                <Typography >Select Vehicle *</Typography>
                </Grid>
                <Grid item xs={4}>
                <FormControl size="small" fullWidth>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={customerdata.select_vehicle}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        select_vehicle: e.target.value,
                        chasis_number: vehicledetailsData.filter(obj => obj.id === e.target.value)[0]?.stock?.chasis_no
                      })
                    }
  >
  {vehicleSelectOption.map((va,index)=>{
    return(
      <MenuItem key={index} value={va.id}>{va.vehicle_name}: {va.variant_name}</MenuItem>
    )
  })}
  </Select>
</FormControl>
                </Grid>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={2}>
                <Typography >Chasis Number *</Typography>
                </Grid>
                <Grid item xs={4}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                readOnly
                value={customerdata.chasis_number}
                InputProps={{
                readOnly: true,
              }}
              />
                </Grid>
                <Grid item xs={2}>
                <Typography >Number of Service *</Typography>
                </Grid>
                <Grid item xs={4}>
                <FormControl size="small" fullWidth>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={customerdata.number_service}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        number_service: e.target.value,
                      })
                    }
  >
    <MenuItem value="1st Service">1st Service</MenuItem>
    <MenuItem value="2nd Service">2nd Service</MenuItem>
    <MenuItem value="3rd Service">3rd Service</MenuItem>
  </Select>
</FormControl>
                </Grid>
        </Grid>
        <Divider sx={{mb: 2}}/>   
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems: "center" }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <Typography variant="h6">Type of service</Typography>
          </Grid>
          <Grid item xs={2}>
                <Typography >Meter Reading *</Typography>
                </Grid>
                <Grid item xs={2}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={customerdata.meter_reading}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        meter_reading: e.target.value,
                      })
                    }
              />
                </Grid>
          <Grid item xs={2}>
                <Typography >Repeat Job *</Typography>
                </Grid>
                <Grid item xs={2}>
                <FormControl size="small" fullWidth>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={customerdata.repeat_job}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        repeat_job: e.target.value,
                      })
                    }
  >
    <MenuItem value="Maintanence">Maintenance</MenuItem>
    <MenuItem value="Reapeated">Reapeated</MenuItem>
    <MenuItem value="Accident">Accident</MenuItem>
  </Select>
</FormControl>
                </Grid>
                <Grid item xs={2}>
                <Typography >RSA/ON Site *</Typography>
                </Grid>
                <Grid item xs={2}>
                <FormControl size="small" fullWidth>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={customerdata.rsa_on_site}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        rsa_on_site: e.target.value,
                      })
                    }
  >
    <MenuItem value="Yes">Yes</MenuItem>
    <MenuItem value="No">No</MenuItem>
  </Select>
</FormControl>
                </Grid>
        </Grid>  
        <Divider sx={{mb: 2}}/>  
        {customerdata.remarks.map((ev, index)=>{
          return(
            <Box key={index} sx={{mt: 1}}>
                <Typography variant="p">Remarks *</Typography>
                <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <TextField
                multiline
                rows={3}
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                sx={{mt: 1}}
                value={ev.value}
                onChange={(e) => handleFieldChange( ev.field_id, e)}
              />
                <Button onClick={()=> removeField(ev.field_id)}>x</Button>
                </Box>
        </Box> 
          )
        })}
        <Button variant="text" sx={{mt: 1}} onClick={addMoreDetails}>+ Add Remarks</Button>    
        <Divider sx={{my: 2}}/>   
            <Grid container columnSpacing={5} sx={{mt: 2}}>
                  <Grid item xs={6}>
                <Typography variant="h6">Customer Notes *</Typography>
                <TextField
                sx={{mt: 2}}
                rows={4}
                multiline
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={customerdata.notes}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        notes: e.target.value
                      })
                    }
              />
            </Grid>
            <Grid item xs={6}>
            <Typography variant="h6">Speedometer Image *</Typography>
            <CloudUpload setLoader={setLoader} file={customerdata.file_url} setFile={setFile}/>
                </Grid>
            </Grid>
            <Divider sx={{my: 3}}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{textAlign:'end'}}>
                  <Button sx={{mr: 2}} onClick={arrowBack} variant="outlined">Cancel</Button>
                  <Button type="submit" variant="contained">Create Service</Button>
                </Grid>
            </Grid>
      </Box>
    </div>
  );
};

export default Addservice;