import React, { useEffect , useState} from 'react';
import { Box, Grid, Button, Typography, TextField, Divider, Card, Avatar, FormControl, Select, MenuItem } from '@mui/material';
import CustomTable from '../../components/table/Table';
import Adddealer from './Addcustomer';
import { useDispatch, useSelector } from "react-redux";
import SearchButton from '../../components/search/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import {  getenquiry } from '../../slices/enquiry';
import { deepPurple } from '@mui/material/colors';
import { getcustomer } from '../../slices/customer';
import Popup from '../../components/popup/Popup';
import no_data from '../../assets/images/no_data.png';

const Customer = ({setLoader, show}) => {

  const [open, setOpen] = React.useState(false);
  const [modalte, setModalte] = React.useState(null);

  const {customers: cusData} = useSelector((state) => state.customer);

  const [status, setStatus] = React.useState('Name');

  const [enquiryData, setFilteredData] = React.useState(cusData);

  const handleFilterChange = (event) => {
    setStatus(event.target.value);
  };

  
  const handleSearch = (query) => {
    if(status === 'Name'){
      const filtered = cusData.filter(item => (item.first_name+item.last_name).toLowerCase().includes(query));
      setFilteredData(filtered);
    }
    if(status === 'Email'){
      const filtered = cusData.filter(item => (item.email).toLowerCase().includes(query));
      setFilteredData(filtered);
    }
    if(status === 'Phone'){
      const filtered = cusData.filter(item => ((item.customer_phone_mobile).toString()).includes(query));
      setFilteredData(filtered);
    }
  };

  const dispatch = useDispatch();

  const {message: message} = useSelector((state) => state.customer);

  console.log(message);

  useEffect(()=>{
    if(message){
          setOpen(true);
          setModalte(message);
    }
  },[message]);

  useEffect(()=>{
    setLoader(true)
      dispatch(getcustomer())
      .then((response) => {
        console.log(response);
        setFilteredData(response.payload.customerdata.data);
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px'}}>
      <Popup open={open} modalte={modalte} setOpen={setOpen}/>
      <Box>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={4} sx={{paddingTop: 0}}>
            <Typography variant='h5' sx={{fontWeight:'bold'}}>
            My Customers
            </Typography>
          </Grid>
          <Grid item xs={8} sx={{textAlign: 'end', paddingTop: 0}}>
          <Box sx={{display:'flex', justifyContent:'end', gap: 2, alignItems:'center'}}>
          <FormControl size='small' sx={{ width: 100 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          onChange={handleFilterChange}
          sx={{p: 0.2}}
        >
          <MenuItem value="Name">Name</MenuItem>
          <MenuItem value="Phone">Phone</MenuItem>
          <MenuItem value="Email">Email</MenuItem>
        </Select>
      </FormControl>
          <SearchButton handleSearch={handleSearch} setFilteredData={setFilteredData} placeholder="Search Customer"/>
          <Link to='/customer/new'>
          <Button variant='contained' size='large'>+ New Customer</Button>
          </Link>
          </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{mt: 2}}>
        <Grid container spacing={2}>
        {enquiryData?.length === 0 ? 
        <Grid item xs={12} display='flex' justifyContent='center'>
        <img src={no_data} alt='no_data'></img>
              </Grid>
          :
        enquiryData.map((va, index)=>{
          return(
            <Grid key={index} item xs={12}>
            <Link to={`/customer/view/${va.id}`} style={{textDecoration: 'none'}}>
                  <Card sx={{boxShadow:'none', p: 2, border:'1px solid #eaeaea', display:'flex', justifyContent:'space-between', alignItems:'center', borderLeft:'3px solid #0b4c7c'}}>
                  <Grid container spacing={2} sx={{alignItems:'center'}}>
                      <Grid item xs={1}>
                      <Avatar sx={{ bgcolor: deepPurple[500] }}>{(va.first_name)[0]}{(va.last_name)[0]}</Avatar>
                      </Grid>
                      <Grid item xs={3}>
                      <Box>
                      <Typography variant='p'>{va.title} {va.first_name} {va.last_name}</Typography>
                      <Typography variant='body2'>Created On: {`${new Date(va.created_at).getDate()}-${new Date(va.created_at).getMonth()+1}-${new Date(va.created_at).getFullYear()}`}</Typography>
                    </Box>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant='p'>{va.customer_phone_mobile}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant='p'>{va.billing_address.city}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                        <Typography variant='p'>{va.email}</Typography>
                        </Grid>
                        <Grid item xs={2} sx={{textAlign:'end'}}>
                        <Typography variant='p' sx={{bgcolor:'#0b7c22', color:'#fff', px:1, borderRadius:'10px'}}>{va.customer_type}</Typography>
                        </Grid>
                  </Grid>
                  </Card>
                  </Link>
              </Grid>
          )
        })}
        </Grid>
      </Box>
      </Box>
    </div>
  );
}

export default Customer;