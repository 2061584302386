import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  FormGroup,
  Switch,
  Modal,
  Card,
  Checkbox,
} from "@mui/material";
import CustomTable from "../../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import SearchButton from "../../components/search/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getregion } from "../../slices/setting";
import { createcustomer, createinvoice, createpayment, getinvoicebycus, getinvoicebyid, getpaymentbycus, getpaymentbyid, getservicebyid, updateinvoicebyid } from "../../slices/customer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getpurchase } from "../../slices/purchase";
import BasicTabs from "../../components/tabs/Invitab";
import { deleteproductstock, getproductstock } from "../../slices/inventory";
import DeleteIcon from "@mui/icons-material/Delete";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import CustomTable2 from "../../components/table/paytable";
import CustomTable1 from "../../components/table/Invtable";

const columns2 = [
  { id: 'payment_id', label: 'Payment ID', minWidth: 200, align:'start' },
  { id: 'invoice_id', label: 'Invoice ID', minWidth: 150, align:'center' },
  { id: 'total', label: 'Amount', minWidth: 150, align:'center' },
  { id: 'status', label: 'Status', minWidth: 150, align:'center' },
  // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

const columns3 = [
  { id: 'invoice_id', label: 'Invoice ID', minWidth: 150, align:'Start' },
  { id: 'invoice_date', label: 'Invoice Date', minWidth: 150, align:'center' },
  { id: 'total', label: 'Amount', minWidth: 150, align:'center' },
  { id: 'status', label: 'Status', minWidth: 150, align:'center' },
  // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};


const Viewservice = ({ setLoader, show }) => {

  const [open, setOpen] = useState(false);

  const {state} = useLocation();

  console.log(state?.viewData);

  const [moreno, setMoreno] = React.useState(1);

  const moreData = {
    field_id: 0,
    value:""
  }

  const params = useParams()

  const {service: paymentData} = useSelector((state) => state.customer) || [];

  const {payments: paymentDat} = useSelector((state) => state.customer) || [];

  const {invoices: invoiceDat} = useSelector((state) => state.customer) || [];

  const filterPayment = paymentDat.filter(obj => obj.invoice_id === paymentData[0]?.invoice_id)
  const filterInvoice = invoiceDat.filter(obj => obj.invoice_id === paymentData[0]?.invoice_id)

  const user = JSON.parse(localStorage.getItem('user'));

  const dealerState =  JSON.parse(user.billing_address).state; 

  console.log(state.viewData, paymentData, user);

  const [customerdata, setCustomerdata] = useState({
    "service_id": params.serviceId,
    "additional_works":[moreData],
    "requested_rectification": false,
    "invoice_id":""
})

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const arrowBack = () => {
    navigate(-1);
  };

  const handleFieldChange = (field_id, event) => {
    const newValue = event.target.value;
    console.log(field_id)
    setCustomerdata(prevItems => {
          return { ...prevItems, additional_works: customerdata.additional_works.map(item1=>{
            if (item1.field_id === field_id) {
              return { ...item1, value: newValue };
            }
            return item1;
          }) };
        }
      );
  };

  
  const addMoreDetails = () => {
    customerdata.additional_works.push({...moreData, field_id:moreno});
    setMoreno(moreno+1);
  }

  const removeField = (field_id) =>{
    setMoreno(moreno-1);
    setCustomerdata(prevItems => {
          return { ...prevItems, additional_works: customerdata.additional_works.filter(item1=>{
              return (item1.field_id !== field_id) ;
          }) };
      });
  }

  React.useEffect(()=>{
    setLoader(true)
    const id = params.serviceId;
      dispatch(getservicebyid(id))
      dispatch(getpaymentbycus(params.id))
      dispatch(getinvoicebycus(params.id))
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
    <Grid container spacing={2}>
    <Grid
            item
            xs={6}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5">{params.serviceId}</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              justifyContent:'end',
              gap: 1,
            }}
          >

            <LocalPrintshopOutlinedIcon sx={{ cursor: "pointer" }} />
            <Typography variant="h6">Print</Typography>
          </Grid>
    </Grid>
      <Box sx={{mt: 2, border:'1px solid #bababa', p: 3}}>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems: "center" }}>
        <Grid item xs={12} sx={{textAlign:'end'}}>
                <Typography variant="h6">Job Card</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h6">Customer Details</Typography>
                </Grid>
                <Grid item xs={2.4} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Customer Name</Typography>
                <Typography >{state.viewData.first_name} {state.viewData.last_name}</Typography>
                </Grid>
                <Grid item xs={2.4} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Vehicle</Typography>
                <Typography >{paymentData[0]?.select_vehicle}</Typography>
                </Grid>
                <Grid item xs={2.4} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Chassis No.</Typography>
                <Typography >{paymentData[0]?.chasis_number}</Typography>
                </Grid>
                <Grid item xs={2.4} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>No. Service</Typography>
                <Typography >{paymentData[0]?.number_service}</Typography>
                </Grid>
                <Grid item xs={2.4} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Date</Typography>
                <Typography >{`${(new Date(paymentData[0]?.created_at)).getDate()} / ${(new Date(paymentData[0]?.created_at)).getMonth()+1} / ${(new Date(paymentData[0]?.created_at)).getFullYear()}`}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h6">Type of Service</Typography>
                </Grid>
                <Grid item xs={2.4} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Milage Up</Typography>
                <Typography >{paymentData[0]?.meter_reading}</Typography>
                </Grid>
                <Grid item xs={2.4} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Job Type</Typography>
                <Typography >{paymentData[0]?.repeat_job}</Typography>
                </Grid>
                <Grid item xs={2.4} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>RSA/ON Site</Typography>
                <Typography >{paymentData[0]?.rsa_on_site}</Typography>
                </Grid>
                <Grid item xs={2.4} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Service Type</Typography>
                <Typography >Paid</Typography>
                </Grid>
                <Grid item xs={12}>
                <Divider/>           
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h6">Remarks</Typography>
                </Grid>
                {paymentData[0]?.remarks?.map((va, index) => {
                  return(
                    <Grid key={index} item xs={12} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Remarks #{index+1}</Typography>
                <Typography >{va?.value}</Typography>
                </Grid>
                  )
                })}
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={12} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Customer Notes</Typography>
                <Typography >{paymentData[0]?.notes}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={12}>
                <Typography variant="h6">Job Receipt</Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>{paymentData[0]?.select_vehicle}</Typography>
                <Typography >Contact No <a href={`tel:${state.viewData.customer_phone_mobile}`}>{state.viewData.customer_phone_mobile}</a> </Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>{paymentData[0]?.select_vehicle}</Typography>
                <Typography >{paymentData[0]?.chasis_number}</Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>{paymentData[0]?.number_service}</Typography>
                <Typography >{`${(new Date(paymentData[0]?.created_at)).getDate()} / ${(new Date(paymentData[0]?.created_at)).getMonth()+1} / ${(new Date(paymentData[0]?.created_at)).getFullYear()}`}</Typography>
                  </Grid>
                  <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Signature of Customer</Typography>
                  </Grid>
        </Grid>
        <Divider sx={{mb: 2}}/>           
            <Grid container sx={{p: 2, pb: 5, background:'#eaeaea'}}>
                  <Grid item xs={8}>
                <Typography >Manager Signature</Typography>
            </Grid>
            <Grid item xs={4} sx={{textAlign:'center'}}>
            <Typography>Technician Signature </Typography>
                </Grid>
            </Grid>
      </Box>
      {filterPayment.length>0 &&
        <Grid container spacing={2} sx={{mt: 2}}>
          <Grid item xs={12}>
          <CustomTable2 viewData={state.viewData} columns={columns2} rows={filterPayment}/>
          </Grid>
      </Grid>
      }
      {filterInvoice.length>0 &&
        <Grid container spacing={2} sx={{mt: 2}}>
          <Grid item xs={12}>
          <CustomTable1 viewData={state.viewData} columns={columns3} rows={filterInvoice}/>
          </Grid>
      </Grid>
      }
      {paymentData[0]?.status !== "Completed" &&
      <Grid item xs={12} sx={{textAlign:'end', mt: 3}}>
          <Button onClick={()=> setOpen(true)} variant="contained">Complete Service</Button>
      <Modal
                        open={open}
                        onClose={()=> setOpen(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                        <Typography variant="h6">Complete Service</Typography>
                        <Divider orientation="horizontal" sx={{ my: 2 }} />
                        <Box>
            <Card sx={{ boxShadow: "none", mb: 2}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
          <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Milage Up</Typography>
                <Typography >{paymentData[0]?.meter_reading}</Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Job Type</Typography>
                <Typography >{paymentData[0]?.repeat_job}</Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>RSA/ON Site</Typography>
                <Typography >{paymentData[0]?.rsa_on_site}</Typography>
                </Grid>
                <Grid item xs={3} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Service Type</Typography>
                <Typography >Paid</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={12} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Customer Notes</Typography>
                <Typography >{paymentData[0]?.notes}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={12} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Requested Rectification</Typography>
                <FormGroup>
      <FormControlLabel control={<Checkbox checked={customerdata.requested_rectification}
      onChange={()=> setCustomerdata({...customerdata, requested_rectification: true})}/>} label="Test" />
      </FormGroup>
                      </Grid>
                <Grid item xs={12}>
                  <Divider/>
                </Grid>
                <Grid item xs={12} >
                <Typography variant="body1" sx={{fontSize: 15, fontWeight:'medium'}}>Additional Work</Typography>
                {customerdata.additional_works.map((ev, index)=>{
          return(
            <Box key={index} sx={{mt: 1}}>
                <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                sx={{mt: 1}}
                value={ev.value}
                onChange={(e) => handleFieldChange( ev.field_id, e)}
              />
                <Button onClick={()=> removeField(ev.field_id)}>x</Button>
                </Box>
        </Box> 
          )
        })}
        <Button variant="text" sx={{mt: 1}} onClick={addMoreDetails}>+ Add More</Button> 
                </Grid>
          </Grid>
      </Card>
                          </Box>
                          <Divider orientation="horizontal" sx={{ my: 2 }} />
                            <Box sx={{ textAlign: "end" }}>
                            <Button variant="outlined"
                            onClick={()=> setOpen(false)}
                            sx={{mr: 1}}
                            >
                                Cancel
                              </Button>
                              {

                              }
                              <Link to={`/customer/view/${state.viewData.id}/newserviceinvoice`} state={{cusData: state.viewData, serviceData: customerdata}}>
                              <Button variant="contained" onClick={()=>{
                              setOpen(false);
                              }}>
                                Complete Service
                              </Button>      </Link>
                            </Box>
                        </Box>
                      </Modal> 
        </Grid>
      }
    </div>
  );
};

export default Viewservice;