import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  FormGroup,
  Switch,
  Modal,
  Card,
} from "@mui/material";
import CustomTable from "../../components/table/Table";
import { useDispatch, useSelector } from "react-redux";
import SearchButton from "../../components/search/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getregion } from "../../slices/setting";
import { createcustomer, createinvoice, createpayment, getinvoicebycus, updateinvoicebyid } from "../../slices/customer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getpurchase } from "../../slices/purchase";
import BasicTabs from "../../components/tabs/Invitab";
import { deleteproductstock, getproductstock } from "../../slices/inventory";
import DeleteIcon from "@mui/icons-material/Delete";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};


const Addpayment = ({ setLoader, show }) => {

  const [open, setOpen] = useState(false);

  const {state} = useLocation();

  const [file, setFile] = useState(null);

  const [selecItem, setSelectItem] = useState();

  const [selectedItem, setSelectedItem] = useState("");


  const {invoices: invoiceData} = useSelector((state) => state.customer) || [];

  const user = JSON.parse(localStorage.getItem('user'));


  console.log(invoiceData);

  const [bankper, setBankPer] = useState();
  const [taxdeduc, setTaxDeduc] = useState();

  const [customerdata, setCustomerdata] = useState({
      "dealer_id": user?.dealer_id,
      "customer_id": state?.cusData?.id,
      "customer_name": state?.cusData?.first_name,
      "invoice_id":"",
      "payment_type":"Advance",
      "payment_date":"",
      "payment_mode":"",
      "file_url":file,
      "notes":"",
      "amount":"",
      "bank_charges":"",
      "tax_deducted": 0,
      "transaction_id":"",
      "status":"",
      "total":""
  })

  useEffect(()=>{
    if(file){
      setCustomerdata({...customerdata, file_url: file})
    }
  },[file])

  const handleAssign = (ec, pay) => {
    console.log(ec);
    setSelectItem(ec);
    setCustomerdata({...customerdata, amount: pay})
  }

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const arrowBack = () => {
    navigate(-1);
  };

  const invId = invoiceData.filter(va => va.status !== 'Paid' && va.status !== 'Pending').map(obj =>{return obj}).flat();

  console.log(invId)

  const bankCharges = customerdata.amount*bankper/100;

  const taxCharges = customerdata.amount*taxdeduc/100;


    const total = Number(customerdata.amount)+ Number(bankCharges);

    // const total = Number(customerdata.amount);



  const handleSubmit = (e) => {
    e.preventDefault();

    setLoader(true);
    if(customerdata.payment_type === "Advance"){
      if(invId.length>0){
        dispatch(createpayment({...customerdata, bank_charger: bankCharges, tax_deducted: taxCharges,
          status:"Paid",
          invoice_id: invId[0].invoice_id,
          total: total }))
          .then(() => {
            navigate(-1);
            setLoader(false);
            const formData = {status: Number(invId[0].pending_payments) === Number(customerdata.amount) ? 'Paid':'Pending', pending_payments: invId[0].pending_payments-customerdata.amount };
            const inv_id = invId[0].invoice_id
            dispatch(updateinvoicebyid({inv_id, formData}))
          })
          .catch(()=>{
            setLoader(false);
          })
      }
      else{
        dispatch(createpayment({...customerdata, bank_charger: bankCharges, tax_deducted: taxCharges,
          status:"Paid",
          total: total }))
          .then(() => {
            navigate(-1);
            setLoader(false);
          })
          .catch(()=>{
            setLoader(false);
          })
      }
    }
    else{
      dispatch(createpayment({...customerdata, bank_charger: bankCharges, tax_deducted: taxCharges,
        invoice_id: selectedItem,
        status:"Paid",
        total: total }))
        .then(() => {
          navigate(-1);
          setLoader(false);
          const formData = {status: Number(invId.filter(va => va.invoice_id === selectedItem)[0].pending_payments) === Number(customerdata.amount) ? 'Paid':'Pending', pending_payments: invId.filter(va => va.invoice_id === selectedItem)[0].pending_payments-customerdata.amount };
            const inv_id = selectedItem
            dispatch(updateinvoicebyid({inv_id, formData}))
        })
        .catch(()=>{
          setLoader(false);
        })
    }
  };

  
  React.useEffect(()=>{
    setLoader(true)
      dispatch(getinvoicebycus(state?.cusData?.id))
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
      <Box  component="form"
    noValidate
    autoComplete="off"
    onSubmit={handleSubmit}>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems: "center" }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{fontWeight:'bold'}}>New Payment</Typography>
          </Grid>
          <Grid item xs={2}>
                <Typography >Payment Type *</Typography>
                </Grid>
                <Grid item xs={4}>
                <FormControl size="small" fullWidth>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={customerdata.payment_type}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        payment_type: e.target.value,
                      })
                    }
  >
    <MenuItem value="Advance">Advance</MenuItem>
    <MenuItem value="Invoice">Invoice</MenuItem>
  </Select>
</FormControl>
                </Grid>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={2}>
                <Typography >Customer Name *</Typography>
                </Grid>
                <Grid item xs={4}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                readOnly
                value={customerdata.customer_name}
                InputProps={{
                readOnly: true,
              }}
              />
                </Grid>
                <Grid item xs={6}>
                </Grid>
                <Grid item xs={2}>
                <Typography >Payment Date *</Typography>
                </Grid>
                <Grid item xs={4}>
                <TextField
                type="date"
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={customerdata.payment_date}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        payment_date: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={6}>

                </Grid>
                <Grid item xs={2}>
                <Typography >Payment Mode *</Typography>
                </Grid>
                <Grid item xs={2}>
                <FormControl size="small" fullWidth>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={customerdata.payment_mode}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        payment_mode: e.target.value,
                      })
                    }
  >
    <MenuItem value="Cash">Cash</MenuItem>
    <MenuItem value="Card">Card</MenuItem>
    <MenuItem value="UPI">UPI</MenuItem>
    <MenuItem value="Check">Check</MenuItem>
    <MenuItem value="NEFT">NEFT</MenuItem>
    <MenuItem value="RTGS">RTGS</MenuItem>
    <MenuItem value="IMPS">IMPS</MenuItem>
  </Select>
</FormControl>
                </Grid>
                <Grid item xs={2}>
                <Typography >Amount *</Typography>
                </Grid>
                <Grid item xs={2}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={customerdata.amount}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        amount: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={2}>
                <Typography >Bank Charges(%) *</Typography>
                </Grid>
                <Grid item xs={2}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={bankper}
                onChange={(e) =>
                      setBankPer(e.target.value)
                    }
              />
                </Grid>
                {/* <Grid item xs={2}>
                <Typography >Tax Deducted(%) *</Typography>
                </Grid>
                <Grid item xs={2}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={taxdeduc}
                onChange={(e) =>
                      setTaxDeduc(e.target.value)
                    }
              />
                </Grid> */}
                <Grid item xs={2}>
                <Typography >Transaction ID *</Typography>
                </Grid>
                <Grid item xs={2}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={customerdata.transaction_id}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        transaction_id: e.target.value,
                      })
                    }
              />
                </Grid>
        </Grid>
        <Divider sx={{mb: 2}}/>           
    <Grid container spacing={2}>
    {
                  customerdata?.payment_type === 'Invoice' ? (selectedItem === "" ?
                  <>
                <Grid item xs={6}>
                <Button onClick={() => setOpen(true)} variant="text" size="small" >+ Link Invoice</Button>
                  <Modal
                        open={open}
                        onClose={()=> setOpen(false)}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                        <Typography variant="h6">Add Invoice</Typography>
                        <Divider orientation="horizontal" sx={{ my: 2 }} />
                        <Box>
                                  {
                                    invId.filter(obj => !(selectedItem === obj.invoice_id)).map((ec, index)=>{
          return(
            <Card onClick={()=> handleAssign(ec.invoice_id, ec.pending_payments)} key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 2, borderColor: selecItem === ec.invoice_id && 'green'}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={12}>
                  <Typography variant="h5" sx={{fontWeight:'bold'}}>{ec.invoice_id}</Typography>
              </Grid>
              <Grid item xs={4}>
                  <Typography variant='p'>Invoice Date: </Typography>
                  <Typography variant='p'>{ec.invoice_date}</Typography>
              </Grid>
              <Grid item xs={4}>
              <Typography variant='p'>Amount: </Typography>
                  <Typography variant='p'>{ec.total}</Typography>
              </Grid>
              <Grid item xs={4}>
              <Typography variant='p'>Due Amount: </Typography>
                  <Typography variant='p'>{ec?.pending_payments}</Typography>
              </Grid>
          </Grid>
      </Card>
          )
        })
      }
                          </Box>
                          { invId.filter(obj => !(selectedItem === obj.invoice_id)).length === 0 && <Typography variant="p">No Invoice Pending to make payment.</Typography>}
                          <Divider orientation="horizontal" sx={{ my: 2 }} />
                            <Box sx={{ textAlign: "end" }}>
                            <Button variant="outlined"
                            onClick={()=> setOpen(false)}
                            sx={{mr: 1}}
                            >
                                Cancel
                              </Button>
                              <Button variant="contained" onClick={()=>{
                              setSelectedItem(selecItem);
                              setOpen(false);
                              }}>
                                Confirm
                              </Button>
                            </Box>
                        </Box>
                      </Modal>                  
                      </Grid>
                {/* <Grid item xs={4}>
                <FormControl size="small" fullWidth>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={customerdata.invoice_id}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        invoice_id: e.target.value,
                        amount: invoiceData.filter(obj => obj.invoice_id === e.target.value)[0]?.total
                      })
                    }
  >
  {invId.map((va, index)=>{
    return(
      <MenuItem value={va}>{va}</MenuItem>
    )
  })}
  </Select>
</FormControl>
                </Grid> */}
                </>
                :
                <Grid item xs={6}>
                <Box sx={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                  {
                                    invId.filter(obj => (selectedItem === obj.invoice_id)).map((ec, index)=>{
          return(
            <Card onClick={()=> handleAssign(ec.invoice_id)} key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 2}}>
          <Grid container rowSpacing={2} sx={{p: 2}}>
              <Grid item xs={12}>
                  <Typography variant="h5" sx={{fontWeight:'bold'}}>{ec.invoice_id}</Typography>
              </Grid>
              <Grid item xs={4}>
                  <Typography variant='p'>Invoice Date: </Typography>
                  <Typography variant='p'>{ec.invoice_date}</Typography>
              </Grid>
              <Grid item xs={4}>
              <Typography variant='p'>Amount: </Typography>
                  <Typography variant='p'>{ec.total}</Typography>
              </Grid>
              <Grid item xs={4}>
              <Typography variant='p'>Due Amount: </Typography>
                  <Typography variant='p'>{ec?.pending_payments}</Typography>
              </Grid>
          </Grid>
      </Card>
          )
        })
      }
      <DeleteIcon sx={{color: 'red', ml: 2, fontSize: 26}} onClick={()=> {setSelectedItem("")
      setSelectItem("")
      }}/>
                          </Box>
                </Grid>
                  )
                :
                <Grid item xs={6}>
                </Grid>
                  
                }
          <Grid item xs={3} sx={{textAlign:'end'}}>
              <Typography variant="body1">Amount Received</Typography>
              <Typography  variant="body1">Bank Charges</Typography>
              <Typography  variant="body1">Tax Deducted</Typography>
              <Typography  variant="h6" sx={{mt: 1}}>Total (₹)</Typography>
          </Grid>
          <Grid item xs={3} sx={{textAlign:'end'}}>
              <Typography variant="body1">₹ {customerdata.amount ? customerdata.amount : '0.00'}</Typography>
              <Typography variant="body1">₹ { bankCharges ? bankCharges :'0.00'}</Typography>
              <Typography variant="body1">₹ { taxCharges ? taxCharges :'0.00'}</Typography>
              <Typography variant="h6" sx={{mt: 1}}>₹ {total ? total :'0.00'}</Typography>
          </Grid>
    </Grid>

            <Grid container columnSpacing={5} sx={{mt: 2}}>
                  <Grid item xs={6}>
                <Typography variant="h6">Notes (internal use Not Visible to customer) *</Typography>
                <TextField
                sx={{mt: 2}}
                rows={4}
                multiline
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={customerdata.notes}
                onChange={(e) =>
                      setCustomerdata({
                        ...customerdata,
                        notes: e.target.value
                      })
                    }
              />
            </Grid>
            <Grid item xs={6}>
            <Typography variant="h6">Attach Image</Typography>
                <CloudUpload setLoader={setLoader} file={customerdata.file_url} setFile={setFile}/>
                </Grid>
            </Grid>
            <Divider sx={{my: 3}}/>
            <Grid container spacing={2}>
                <Grid item xs={12} sx={{textAlign:'end'}}>
                  <Button sx={{mr: 2}} onClick={arrowBack} variant="outlined">Cancel</Button>
                  <Button type="submit" variant="contained">Create Payment</Button>
                </Grid>
            </Grid>
      </Box>
    </div>
  );
};

export default Addpayment;