import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomTable from '../table/Table';
import './Tabs.css';
import { Button, Checkbox, Divider, FormControlLabel, FormGroup, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { getenquiry, updateenquiry } from '../../slices/enquiry';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { useRef } from "react";
import { useReactToPrint } from 'react-to-print';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({viewData, setLoader}) {

  const componentRef = useRef();

  const [value, setValue] = React.useState(0);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const user = JSON.parse(localStorage.getItem('user'));

  const initState = {
    subject:"",
    note:"",
    make_schedule: false,
    schedule: dayjs()
  }

  const [updateFollow, setUpdatefollow] = React.useState(initState)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(viewData);

  
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  const dispatch = useDispatch();

  const handleUpdate = (e) =>{
    e.preventDefault();
    const formData = {
      subject: updateFollow.subject,
      note: updateFollow.note,
      make_schedule: updateFollow.make_schedule,
      schedule: updateFollow.make_schedule ? (updateFollow.schedule).format('YYYY-MM-DD HH:mm:ss') : null,
      previous_schedules: [...viewData.previous_schedules, {schedule: viewData.schedule, subject: viewData.subject, note:viewData.note, created_by: viewData.created_by}]
    };
    const e_id = viewData.id;
    setLoader(true);
    dispatch(updateenquiry({ e_id, formData }))
      .then(() => {
        setLoader(false);
        dispatch(getenquiry());
        handleClose();
        setUpdatefollow(initState);
      })
      .catch(() => {
        setLoader(false);
      });
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Follow up" {...a11yProps(0)} sx={{fontWeight:'bold'}}/>
          {/* <Tab label="Quotation" {...a11yProps(1)} sx={{fontWeight:'bold'}}/> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
      {viewData.make_schedule || viewData?.previous_schedules?.length > 0 ?
      <>
          <Grid container spacing={2}>
              <Grid item xs={4}>
                  <Typography variant='h6'>
                      Upcoming Follow
                  </Typography>
                  <Typography variant='body2' sx={{mt: 0.5}}   >
                      {viewData.schedule}
                  </Typography>
                  <Typography variant='body2' sx={{mt: 0.5}}>
                      Schedule Created By : {viewData.created_by}
                  </Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='h6'>
                      Subject
                  </Typography>
                  <Typography variant='body2'>
                      {viewData.subject}
                  </Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='h6'>
                      Note
                  </Typography>
                  <Typography variant='body2'>
                      {viewData.note}
                  </Typography>
              </Grid>
              <Grid item xs={2} sx={{textAlign:'end'}}>
                  <Button onClick={handleOpen} size='small' variant='contained'>Follow Up</Button>
                  <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Update Follow Up
          </Typography>
          <Divider sx={{my: 2}}/>
          <Box component='form' autoComplete='off' noValidate
          onSubmit={handleUpdate}
          >
                  <Grid container spacing={2}>
                <Grid item xs={12}>
                <Typography >Subject *</Typography>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={updateFollow.subject}
                onChange={(e) =>
                      setUpdatefollow({
                        ...updateFollow,
                        subject: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={12}>
                <Typography >Note *</Typography>
                <TextField
                rows={2}
                multiline
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={updateFollow.note}
                onChange={(e) =>
                      setUpdatefollow({
                        ...updateFollow,
                        note: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={12}>
                <FormGroup>
  <FormControlLabel required control={<Checkbox 
    checked={updateFollow.make_schedule}
    onChange={(e)=>{
      setUpdatefollow({
                        ...updateFollow,
                        make_schedule: e.target.checked,
            })
    }}
  />} label="Make Schedule for Call Back" />
</FormGroup>
                </Grid>
                {updateFollow.make_schedule && 
                <>
                <Grid item xs={12}>
                <Typography >Schedule *</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['MobileDateTimePicker']}>
        <DateTimePicker  
           value={updateFollow.schedule}
                onChange={(newValue) =>
                      setUpdatefollow({
                        ...updateFollow,
                        schedule: newValue,
                      })
                    }
        />
      </DemoContainer>
    </LocalizationProvider>
                </Grid>
                </>
                }
                <Grid item xs={12} sx={{textAlign:'end'}}>
                    <Button size='small' onClick={handleClose} variant='outlined' sx={{mr: 1}}>Cancel</Button>
                    <Button type='submit' size='small' variant='contained'>Add Follow Up</Button>
                </Grid>
</Grid>

          </Box>
        </Box>
      </Modal>
              </Grid>
          </Grid>
          <Divider orientation='horizontal' sx={{my: 2}}/>
          {viewData.previous_schedules.map((va, index)=> {
            return(
              <Grid key={index} container spacing={2} sx={{py: 1, alignItems:'center'}}>
              <Grid item xs={3}>
                  {va.schedule}
              </Grid>
              <Divider orientation='vertical' flexItem/>
              <Grid item xs={4}>
                  <Typography variant='h6'>{va.subject}</Typography>
                  <Typography variant='p'>{va.note}</Typography>
              </Grid>
              <Grid item xs={4} sx={{textAlign:'end'}}>
                  {va.created_by}
              </Grid>
          </Grid>
            )
          })}
          </>
          :
          <Grid item xs={12} sx={{textAlign:'center'}}>
                  <Button onClick={handleOpen} size='small' variant='contained'>Add Follow Up</Button>
                  <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Update Follow Up
          </Typography>
          <Divider sx={{my: 2}}/>
          <Box component='form' autoComplete='off' noValidate
          onSubmit={handleUpdate}
          >
                  <Grid container spacing={2}>
                <Grid item xs={12}>
                <Typography >Subject *</Typography>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={updateFollow.subject}
                onChange={(e) =>
                      setUpdatefollow({
                        ...updateFollow,
                        subject: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={12}>
                <Typography >Note *</Typography>
                <TextField
                rows={2}
                multiline
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={updateFollow.note}
                onChange={(e) =>
                      setUpdatefollow({
                        ...updateFollow,
                        note: e.target.value,
                      })
                    }
              />
                </Grid>
                <Grid item xs={12}>
                <FormGroup>
  <FormControlLabel required control={<Checkbox 
    checked={updateFollow.make_schedule}
    onChange={(e)=>{
      setUpdatefollow({
                        ...updateFollow,
                        make_schedule: e.target.checked,
            })
    }}
  />} label="Make Schedule for Call Back" />
</FormGroup>
                </Grid>
                {updateFollow.make_schedule && 
                <>
                <Grid item xs={12}>
                <Typography >Schedule *</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['MobileDateTimePicker']}>
        <DateTimePicker  
           value={updateFollow.schedule}
                onChange={(newValue) =>
                      setUpdatefollow({
                        ...updateFollow,
                        schedule: newValue,
                      })
                    }
        />
      </DemoContainer>
    </LocalizationProvider>
                </Grid>
                </>
                }
                <Grid item xs={12} sx={{textAlign:'end'}}>
                    <Button size='small' variant='outlined' sx={{mr: 1}}>Cancel</Button>
                    <Button type='submit' size='small' variant='contained'>Add Follow Up</Button>
                </Grid>
</Grid>

          </Box>
        </Box>
      </Modal>
              </Grid>
              }
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <div>
    <Grid container spacing={2}>
    <Grid
            item
            xs={6}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >

            <ArrowBackIcon onClick={()=> setValue(0)} sx={{ cursor: "pointer" }} />
            <Typography onClick={()=> setValue(0)}  sx={{ cursor: "pointer" }} variant="h5">Back</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              justifyContent:'end',
              gap: 2,
            }}
          >
          {/* <Box  sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              justifyContent:'end',
              gap: 1,
            }}>
          <FileDownloadOutlinedIcon sx={{ cursor: "pointer" }} />
            <Typography variant="h6">Download PDF</Typography>
            </Box>

            <Box  sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              justifyContent:'end',
              gap: 1,
            }}>
          <ShareOutlinedIcon sx={{ cursor: "pointer" }} />
            <Typography variant="h6">Share</Typography>
            </Box> */}
            <Box
              onClick={handlePrint}
              sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              justifyContent:'end',
              gap: 1,
            }}>
          <LocalPrintshopOutlinedIcon sx={{ cursor: "pointer" }} />
            <Typography variant="h6">Print</Typography>
            </Box>
          </Grid>
    </Grid>
      <Box ref={componentRef} sx={{mt: 2, border:'1px solid #bababa', p: 3}}>
        <Grid container spacing={3} sx={{ paddingBottom: 2, alignItems: "center" }}>
        <Grid item xs={6} sx={{display:'flex', alignItems:'center', justifyContent:'start', gap: 2}}>
                <Typography><img src={user?.logo_url} width={100}></img></Typography>
                <Box>
                <Typography variant="h6">{user.organization_name}</Typography>
                <Typography >{Object.values(JSON.parse(user?.billing_address)).join(', ')}</Typography>
                <Typography >{user.contact}</Typography>
                </Box>
            </Grid>
        <Grid item xs={6} sx={{textAlign:'end'}}>
                <Typography variant="h6">Quotation</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                <Typography >Billed to</Typography>
                <Typography variant="h6">{viewData?.title} {viewData?.first_name} {viewData?.last_name}</Typography>
                <Typography >{viewData?.location}</Typography>
                </Grid>
                <Grid item xs={6}  sx={{textAlign:'end'}}>
                <Typography >Date: {`${(new Date(viewData.created_at)).getDate()} / ${(new Date(viewData.created_at)).getMonth()+1} / ${(new Date(viewData.created_at)).getFullYear()}`}</Typography>
                <Typography >Invoice # : GHF65565</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
               <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{background:'#595959', color:'#fff'}}>Items</TableCell>
            <TableCell align="right" sx={{background:'#595959', color:'#fff'}}>Qty</TableCell>
            <TableCell align="right" sx={{background:'#595959', color:'#fff'}}>Price</TableCell>
            <TableCell align="right" sx={{background:'#595959', color:'#fff'}}>GST</TableCell>
            <TableCell align="right" sx={{background:'#595959', color:'#fff'}}>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              Stor:ie
              </TableCell>
              <TableCell align="right">1</TableCell>
              <TableCell align="right">2000.00</TableCell>
              <TableCell align="right">0.00</TableCell>
              <TableCell align="right">2,000.00</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              Lo:ev
              </TableCell>
              <TableCell align="right">1</TableCell>
              <TableCell align="right">2000.00</TableCell>
              <TableCell align="right">0.00</TableCell>
              <TableCell align="right">2,000.00</TableCell>
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
              Re:one
              </TableCell>
              <TableCell align="right">1</TableCell>
              <TableCell align="right">2000.00</TableCell>
              <TableCell align="right">0.00</TableCell>
              <TableCell align="right">2,000.00</TableCell>
            </TableRow>
        </TableBody>
      </Table>
                </Grid>
               <Grid item xs={6}></Grid>
               <Grid item xs={6}>
               <Grid container sx={{p:2, background:'#eaeaea'}}>
          <Grid item xs={9} sx={{textAlign:'start'}}>
              <Typography variant="body1" sx={{mb: 1}}>Sub Total</Typography>
              <Typography  variant="body1"  sx={{mb: 1}}>Adjustment</Typography>
              <Typography  variant="body1"  sx={{mb: 1}}>GST</Typography>
              <Typography  variant="h6">Total Amount</Typography>
          </Grid>
          <Grid item xs={3} sx={{textAlign:'end'}}>
              <Typography variant="body1" sx={{mb: 1}}>₹ 6,000.00</Typography>
              <Typography variant="body1" sx={{mb: 1}}>₹ 92.00</Typography>
              <Typography variant="body1" sx={{mb: 1}}>₹ 0.00</Typography>
              <Typography variant="h6">₹ 6,092.00</Typography>
          </Grid>
    </Grid>
               </Grid>
        </Grid>
        <Divider sx={{mb: 2}}/>           
        <Grid container sx={{p: 2, pb: 8, background:'#eaeaea'}}>
                  <Grid item xs={6}>
                  <Typography>Signature customer</Typography>
            </Grid>
            <Grid item xs={5} sx={{textAlign:'end'}}>
            <Typography>Signature authority</Typography>
                </Grid>
            </Grid>
      </Box>
    </div>
      </CustomTabPanel>
    </Box>
  );
}