import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import '../../components/tabs/Tabs.css';
import { Button, Divider, FormControl, Grid, MenuItem, Select, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getstate } from '../../slices/setting';
import { createvariant } from '../../slices/product';
import { useNavigate } from 'react-router-dom';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function VariantTabs({priceGroups, variantData, setVariantData, colorGroups, moreData, setLoader, variantIntiData}) {
  const [value, setValue] = React.useState(0);

  const [variant, setVariant] = React.useState(1);

  const [colorsno, setColorsno] = React.useState(1);

  const [moreno, setMoreno] = React.useState(1);


const dispatch = useDispatch();

const user = JSON.parse(localStorage.getItem('user'));

const operatingState = [JSON.parse(user?.billing_address)?.state];

   React.useEffect(()=>{
    setVariantData(prevItems => {
      return prevItems.map(item => {
          return { ...item, price_groups: operatingState?.map((va, index)=>{
            return {...priceGroups, state:va}
          }) };
      });
    });
    // setPriceGroupData(operatingState.map((va, index)=>{
    //   return {...priceGroups, state:va}
    // }))
 }, [])


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleValueChange = (name, id, event) => {
    const newValue = event.target.value;
    setVariantData(prevItems => {
      return prevItems.map(item => {
        if (item.vehicle_id === id) {
          return { ...item, [name]: newValue };
        }
        return item;
      });
    });
  };

  const handlePriceChange = (name, id, state, event) => {
    console.log(name, id, state, event.target.value)
    const newValue = event.target.value;
    setVariantData(prevItems => {
      return prevItems.map((item, index) => {
        if(item.vehicle_id === id){
          return { ...item, price_groups: variantData[index].price_groups.map(item1=>{
            if (item1.state === state) {
              return { ...item1, [name]: newValue };
            }
            return item1;
          }) };
        }
        return item;
      });
    });
  };

  const handleColorChange = (name, id, color_id, event) => {
    const newValue = event.target.value;
    setVariantData(prevItems => {
      return prevItems.map((item, index) => {
        if(item.vehicle_id === id){
          return { ...item, color_details: variantData[index].color_details.map(item1=>{
            if (item1.color_id === color_id) {
              return { ...item1, [name]: newValue };
            }
            return item1;
          }) };
        }
        return item;
      });
    });
  };
  
  const handleFieldChange = (name, id, field_id, event) => {
    const newValue = event.target.value;
    setVariantData(prevItems => {
      return prevItems.map((item, index) => {
        if(item.vehicle_id === id){
          return { ...item, other_details: variantData[index].other_details.map(item1=>{
            if (item1.field_id === field_id) {
              return { ...item1, [name]: newValue };
            }
            return item1;
          }) };
        }
        return item;
      });
    });
  };

  const addVariant = () => {
    variantData.push({...variantIntiData, vehicle_id:variant+1})
    setVariant(variant+1);
    setValue(variant)
  }

  const addColors = (id) => {
    variantData.map((item, index) => {
        if(item.vehicle_id === id){
          item.color_details.push({...colorGroups, color_id:colorsno+1}) };
        })
    setColorsno(colorsno+1);
  }

  const addMoreDetails = (id) => {
    variantData.map((item, index) => {
        if(item.vehicle_id === id){
          item.other_details.push({...moreData, field_id:moreno}) };
        })
    setMoreno(moreno+1);
  }

  const removeVariant = (id) =>{
      const remainData =variantData.filter((v)=> {return(v.vehicle_id !== id)});
      setVariant(variant-1);
      setVariantData(remainData);
      setValue(variant-2);
  }

  const removeColor = (id, color_id) =>{
    setColorsno(colorsno-1);
    setVariantData(prevItems => {
      return prevItems.map((item, index) => {
        if(item.vehicle_id === id){
          return { ...item, color_details: variantData[index].color_details.filter(item1=>{
              return (item1.color_id !== color_id) ;
          }) };
        }
        return item;
      });
    });
}

const removeField = (id, field_id) =>{
  setMoreno(moreno-1);
  setVariantData(prevItems => {
    return prevItems.map((item, index) => {
      if(item.vehicle_id === id){
        return { ...item, other_details: variantData[index].other_details.filter(item1=>{
            return (item1.field_id !== field_id) ;
        }) };
      }
      return item;
    });
  });
}

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {Array.from(Array(variant)).map((_, index)=>{
            return(
              <Tab key={index} label={`Variant ${index+1}`} {...a11yProps(index)} sx={{fontWeight:'bold'}}/>
            )
          })}
          <Tab onClick={addVariant} label="+ Add Variant" sx={{fontWeight:'bold'}}/>
        </Tabs>
      </Box>
      {variantData.map((va, index)=>{
            return(
              <CustomTabPanel key={index} value={value} index={index}>
              <Grid container spacing={2} sx={{alignItems:'center'}}>
              <Grid item xs={1.5}>
                <Typography >Variant Name* </Typography>
                </Grid>
                <Grid item xs={2}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.variant_name}
                onChange={(e) => handleValueChange('variant_name', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={1.5}>
                <Typography >Release Date * </Typography>
                </Grid>
                <Grid item xs={2}>
                <TextField
                size="small"
                type='date'
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.released_date}
                onChange={(e) => handleValueChange('released_date', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={1}>
                <Typography >Subsidy * </Typography>
                </Grid>
                <Grid item xs={2}>
                <FormControl size="small" fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={va?.subsidy}
          onChange={(e) => handleValueChange('subsidy', va.vehicle_id, e)}
        >
            <MenuItem value="Available">Available</MenuItem>
            <MenuItem value="Un Available">Un Available</MenuItem>
        </Select>
        </FormControl>
                </Grid>
                  <Grid item xs={2} sx={{textAlign:'end'}}>
                  {value !== 0 && <Button variant='contained' onClick={()=>removeVariant(va.vehicle_id)}>x Remove</Button>}
                  </Grid>
                  </Grid>
              <Divider fullWidth sx={{my: 2}}/> 
              <Grid container spacing={2} >
              <Grid item xs={4}>
                <Typography variant='h6' >Motor Details</Typography>
                <Grid item container spacing={2} sx={{alignItems:'center', mt: 1}}>
                <Grid item xs={4}>
                <Typography >Motor Name </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.motor_name}
                onChange={(e) => handleValueChange('motor_name', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Motor Type </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.motor_type}
                onChange={(e) => handleValueChange('motor_type', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Voltage</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.motor_voltage}
                onChange={(e) => handleValueChange('motor_voltage', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Capacity</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.motor_capacity}
                onChange={(e) => handleValueChange('motor_capacity', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty (in Km) </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.motor_warranty_km}
                onChange={(e) => handleValueChange('motor_warranty_km', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty (in Months) </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.motor_warranty_months}
                onChange={(e) => handleValueChange('motor_warranty_months', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty Type </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va[index]?.motor_warranty_type}
                onChange={(e) => handleValueChange('motor_warranty_type', va.vehicle_id, e)}
              />
                </Grid>
                </Grid>
                </Grid>
                <Grid item xs={4}>
                <Typography variant='h6'>Battery Details</Typography>
                <Grid item container spacing={2} sx={{alignItems:'center', mt: 1}}>
                <Grid item xs={4}>
                <Typography >Battery Name</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.battery_name}
                onChange={(e) => handleValueChange('battery_name', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Battery Type</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.battery_type}
                onChange={(e) => handleValueChange('battery_type', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Life Cycle</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.battery_lifecycle}
                onChange={(e) => handleValueChange('battery_lifecycle', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Capacity</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.battery_capacity}
                onChange={(e) => handleValueChange('battery_capacity', va.vehicle_id, e)}
              />
                </Grid>
                
                <Grid item xs={4}>
                <Typography >Warranty (in KM)</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.battery_warranty_km}
                onChange={(e) => handleValueChange('battery_warranty_km', va.vehicle_id, e)}
              />
                </Grid>
                
                <Grid item xs={4}>
                <Typography >Warranty (in Months) </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.battery_warranty_months}
                onChange={(e) => handleValueChange('battery_warranty_months', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty Type </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va[index]?.battery_warranty_type}
                onChange={(e) => handleValueChange('battery_warranty_type', va.vehicle_id, e)}
              />
                </Grid>
                </Grid>
                </Grid>
              <Grid item xs={4}>
                <Typography variant='h6'>Charger Details</Typography>
                <Grid item container spacing={2} sx={{alignItems:'center', mt: 1}}>
                <Grid item xs={4}>
                <Typography >Charger Name</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.charger_name}
                onChange={(e) => handleValueChange('charger_name', va.vehicle_id, e)}
              />
                              </Grid>
                              <Grid item xs={4}>
                <Typography >Capacity</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va[index]?.charger_capacity}
                onChange={(e) => handleValueChange('charger_capacity', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Charger Type</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.charger_type}
                onChange={(e) => handleValueChange('charger_type', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty (in KM)</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.charger_warranty_km}
                onChange={(e) => handleValueChange('charger_warranty_km', va.vehicle_id, e)}
              />
              </Grid>
              <Grid item xs={4}>
                <Typography >Warranty (in Months)</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.charger_warranty_months}
                onChange={(e) => handleValueChange('charger_warranty_months', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty Type</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va[index]?.charger_warranty_type}
                onChange={(e) => handleValueChange('charger_warranty_type', va.vehicle_id, e)}
              />
                </Grid>
                </Grid>
                </Grid>
                </Grid>
                <Divider fullWidth sx={{my: 2}}/> 
              <Grid container spacing={2} sx={{alignItems:'center'}}>
              <Grid item xs={4}>
                <Typography variant='h6' >Converter Details</Typography>
                <Grid item container spacing={2} sx={{alignItems:'center', mt: 1}}>
                <Grid item xs={4}>
                <Typography >Converter Name </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.converter_name}
                onChange={(e) => handleValueChange('converter_name', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Capacity </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.converter_capacity}
                onChange={(e) => handleValueChange('converter_capacity', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty (in Km)</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.converter_warranty_km}
                onChange={(e) => handleValueChange('converter_warranty_km', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty (in Months)</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.converter_warranty_months}
                onChange={(e) => handleValueChange('converter_warranty_months', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty Type </Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.converter_warranty_type}
                onChange={(e) => handleValueChange('converter_warranty_type', va.vehicle_id, e)}
              />
                </Grid>
                </Grid>
                </Grid>
                <Grid item xs={4}>
                <Typography variant='h6'>Controller Details</Typography>
                <Grid item container spacing={2} sx={{alignItems:'center', mt: 1}}>
                <Grid item xs={4}>
                <Typography >Controller Name</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.controller_name}
                onChange={(e) => handleValueChange('controller_name', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Capacity</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.controller_capacity}
                onChange={(e) => handleValueChange('controller_capacity', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty (in Km)</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.controller_warranty_km}
                onChange={(e) => handleValueChange('controller_warranty_km', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty (in Months)</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.controller_warranty_months}
                onChange={(e) => handleValueChange('controller_warranty_months', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty Type</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.controller_warranty_type}
                onChange={(e) => handleValueChange('controller_warranty_type', va.vehicle_id, e)}
              />
                </Grid>
                </Grid>
                </Grid>
              <Grid item xs={4}>
                <Typography variant='h6'>Display Details</Typography>
                <Grid item container spacing={2} sx={{alignItems:'center', mt: 1}}>
                <Grid item xs={4}>
                <Typography >Display Name</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.display_name}
                onChange={(e) => handleValueChange('display_name', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Display Type</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.display_type}
                onChange={(e) => handleValueChange('display_type', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty (in Km)</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.display_warranty_km}
                onChange={(e) => handleValueChange('display_warranty_km', va.vehicle_id, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <Typography >Warranty (in Months)</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.display_warranty_months}
                onChange={(e) => handleValueChange('display_warranty_months', va.vehicle_id, e)}
              />
              </Grid>
              <Grid item xs={4}>
                <Typography >Warranty Type</Typography>
                </Grid>
                <Grid item xs={8}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={va?.display_warranty_type}
                onChange={(e) => handleValueChange('display_warranty_type', va.vehicle_id, e)}
              />
                </Grid>
                </Grid>
                </Grid>
                </Grid>
                <Divider fullWidth sx={{my: 2}}/> 
              <Grid container spacing={2} >
              <Grid item xs={4}>
                <Typography variant='h6' >Price Groups</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                  <Typography variant='p' sx={{fontSize: 14}}>
                  States
                  </Typography>
                  </Grid>
                  <Grid item xs={4}>
                  <Typography variant='p' sx={{fontSize: 14}}>
                  Ex-Showroom Price
                  </Typography>
                  </Grid>
                  <Grid item xs={4}>
                  <Typography variant='p' sx={{fontSize: 14}}>
                  Dealer Price
                  </Typography>                  
                  </Grid>
                  {va?.price_groups?.map((vaa, index)=>{
                    return(
                      <>
                    <Grid item xs={4}>
                <Typography >{vaa.state}</Typography>
                </Grid>
                <Grid item xs={4}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={vaa.ex_showroom}
                onChange={(e) => handlePriceChange('ex_showroom', va.vehicle_id, vaa.state, e)}
              />
                </Grid>
                <Grid item xs={4}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={vaa?.dealer}
                onChange={(e) => handlePriceChange('dealer', va.vehicle_id, vaa.state, e)}
              />
                </Grid>
                </>
                    )
                  })}
                </Grid>
                </Grid>
                <Grid item xs={4}>
                <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'start'}}>
                <Typography variant='h6'>Colour Details</Typography>
                <Button onClick={()=>addColors(va.vehicle_id)} variant='text'>+ Add more color</Button>
                </Box>
                {va?.color_details.map((ev, index)=>{
                  return(
                    <Grid key={index} container spacing={2} sx={{mb: 2}}>
                <Grid item xs={5}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={ev?.name}
                onChange={(e) => handleColorChange('name',  va.vehicle_id, ev.color_id, e)}
              />
                </Grid>
                <Grid item xs={5}>
                <TextField
                size="small"
                fullWidth
                type='color'
                id="outlined-basic"
                variant="outlined"
                value={ev?.code}
                onChange={(e) => handleColorChange('code', va.vehicle_id, ev.color_id, e)}
              />
                </Grid>
                {ev.color_id !== 1 &&
                <Grid item xs={1}>
                <Button onClick={()=> removeColor(va.vehicle_id, ev.color_id)}>x</Button>
                </Grid>
                }
                </Grid>
                  )
                })}
                </Grid>
                <Grid item xs={4}>
                <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'start'}}>
                <Typography variant='h6'>Other Details</Typography>
                <Button variant='text' onClick={()=>addMoreDetails(va.vehicle_id)}>+ Add more details</Button>
                </Box>
                {va?.other_details.map((ev, index)=>{
                  return(
                    <Grid key={index} container spacing={2} sx={{mb: 2}}>
                <Grid item xs={5}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={ev?.field}
                onChange={(e) => handleFieldChange('field', va.vehicle_id, ev.field_id, e)}
              />
                </Grid>
                <Grid item xs={5}>
                <TextField
                size="small"
                fullWidth
                id="outlined-basic"
                variant="outlined"
                value={ev?.value}
                onChange={(e) => handleFieldChange('value', va.vehicle_id, ev.field_id, e)}
              />
                </Grid>
                <Grid item xs={1}>
                <Button onClick={()=> removeField(va.vehicle_id, ev.field_id)}>x</Button>
                </Grid>
                </Grid>
                  )
                })}
                </Grid>
                </Grid>
      </CustomTabPanel>            
      )
          })}
    </Box>
  );
}