import React, { useEffect } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import CustomTable from '../../components/table/Table';
import { useDispatch, useSelector } from "react-redux";
import SearchButton from '../../components/search/Search';
import { getservicebydealer, getwarrantybydealer } from '../../slices/customer';
import CustomTable3 from '../../components/table/serviceopentable';
import { useState } from 'react';

const columns3 = [
  { id: 'service_id', label: 'Service ID', minWidth: 200, align:'start' },
  { id: 'number_service', label: 'Service No', minWidth: 150, align:'center' },
  { id: 'created_at', label: 'Service Date', minWidth: 150, align:'center' },
  { id: 'status', label: 'Status', minWidth: 150, align:'center' },
  // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];
  

const Service = ({setLoader, show}) => {

  const [filteredData, setFilteredData] = useState([]);

  const {dealerservices: serviceData} = useSelector((state) => state.customer) || [];

  const {customers: enquiryData} = useSelector((state) => state.customer) || [];

  const dispatch = useDispatch();

  const handleSearch = (query) => {
    const filtered = serviceData.filter(item => (item.service_id).toLowerCase().includes(query));
    setFilteredData(filtered);
};

  useEffect(()=>{
    setLoader(true)
      dispatch(getservicebydealer())
      .then((response) => {
        setFilteredData(response.payload.customerdata.data);
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className='content' style={{marginLeft: show ? '220px' : '10px'}}>
      <Box>
        <Grid container sx={{ paddingBottom: 2, alignItems:'center' }}>
          <Grid item xs={8} sx={{paddingTop: 0}}>
            <Typography variant='h5'>
            Services
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{textAlign: 'end', paddingTop: 0}}>
          <Box sx={{display:'flex', justifyContent:'end', alignItems:'center'}}>
          <SearchButton handleSearch={handleSearch} setFilteredData={setFilteredData} placeholder="Search Service ID"/>
          </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
      <CustomTable3 viewData={enquiryData} columns={columns3} rows={filteredData}/>
        </Box>
    </div>
  );
}

export default Service;